import React, { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import Gro_logo from"../Assets/Images/OnlyLogo.png"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get current route

  const linkClasses = (path) =>
    location.pathname === path
      ? "text-blue-500 font-bold"
      : "hover:text-gray-500";

  return (
    <nav className="bg-white text-black mt-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <NavLink to="/" className="text-2xl font-bold">
            <img
            src={Gro_logo}
            alt="Company Logo"
            className="w-auto h-20"
          />
            </NavLink>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6">
            <NavLink to="/" className={linkClasses("/")}>
              Home
            </NavLink>
            <NavLink to="/services" className={linkClasses("/services")}>
              Services
            </NavLink>
            <NavLink to="/about" className={linkClasses("/about")}>
              About
            </NavLink>
            <NavLink to="/contact" className={linkClasses("/contact")}>
              Contact
            </NavLink>
            <NavLink to="/blog" className={linkClasses("/blog")}>
              Blog
            </NavLink>
          </div>

          {/* Search Button */}
          <div className="hidden md:block">
            <button className="bg-blue-500 px-4 py-2 text-white rounded hover:bg-blue-600">
              Search
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-800 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-800"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-800">
          <NavLink to="/" className="block px-4 py-2 text-white hover:bg-gray-700">
            Home
          </NavLink>
          <NavLink
            to="/services"
            className="block px-4 py-2 text-white hover:bg-gray-700"
          >
            Services
          </NavLink>
          <NavLink
            to="/about"
            className="block px-4 py-2 text-white hover:bg-gray-700"
          >
            About
          </NavLink>
          <NavLink
            to="/contact"
            className="block px-4 py-2 text-white hover:bg-gray-700"
          >
            Contact
          </NavLink>
          <NavLink
            to="/blog"
            className="block px-4 py-2 text-white hover:bg-gray-700"
          >
            Blog
          </NavLink>
          <button className="bg-blue-500 w-full px-4 py-2 mt-2 text-white hover:bg-blue-600">
            Search
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
