import React from "react";
import onlyLogo from "../Assets/Images/OnlyLogo.png"

const ImageTextSection = () => {
  return (
    <section className="bg-white flex items-center pb-20">
      <div className="container mx-auto px-6 lg:px-16 flex flex-col-reverse lg:flex-row items-center justify-between">
        {/* Left Image Section */}
        <div className="lg:w-1/2">
          <img
            src={onlyLogo} // Replace with your image URL
            alt="Empowering Individuals"
            className="w-screen md:w-full "
          />
        </div>

        {/* Right Text Section */}
        <div className="lg:w-1/2 text-center lg:text-left space-y-6">
          <h2 className="text-3xl sm:text-7xl font-bold text-gray-800">
          Who are we
          </h2>
          <p className="text-gray-500 text-lg">
          At Gro - Guileless Resources and Outcomes Limited, we are a leading 360-degree Human Capital Solutions provider, committed to empowering individuals, organizations, and educational institutions. Our expertise spans Consulting, Training, Recruitment, and Event Management, delivering innovative and customized solutions for growth and excellence.
          </p>
          <p className="text-gray-500 text-lg">
          With a mission to bridge the gap between talent and opportunity, we strive to create transformative impacts in education, employment, and organizational development, fostering a future of success and sustainability.
          </p>
          <button className="bg-[#6293CC] text-white px-6 py-3 rounded-lg text-lg font-medium hover:bg-blue-600">
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
};

export default ImageTextSection;
