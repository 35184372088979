import React from "react";

// ContentCard Component
function ContentCard({ img, title, desc }) {
  return (
    <div className="relative grid min-h-[30rem] items-end overflow-hidden rounded-xl shadow-lg hover:scale-105 transition-transform duration-300">
      <img
        src={img}
        alt={`${title} background image`}
        className="absolute inset-0 h-full w-full object-cover object-center"
      />
      <div className="absolute inset-0 bg-black/70" />
      <div className="relative p-6 text-white">
        <h4 className="text-3xl font-bold">{title}</h4>
        <p className="mt-2 text-lg">{desc}</p>
      </div>
    </div>
  );
}

// BlogSection11 Component
export function BlogSection11() {
  const contents = [
    {
      img: "https://www.material-tailwind.com/image/blog-11.jpeg",
      title: "Search and Discovery",
      desc: "Website visitors today demand a frictionless user experience — especially when using search. Because of the high standards we tend to offer.",
    },
    {
      img: "https://www.material-tailwind.com/image/blog-10.jpeg",
      title: "Last visits in US",
      desc: "Wealth creation is an evolutionarily recent positive-sum game. Status is an old zero-sum game. Those attacking wealth creation are often just seeking status.",
    },
    {
      img: "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/card-blog2.jpg",
      title: "Grow in a beautiful area",
      desc: "Free people make free choices. Free choices mean you get unequal outcomes. You can have freedom, or you can have equal outcomes. You can't have both.",
    },
  ];

  return (
    <section className="container mx-auto px-8 py-10 lg:py-28">
      <h2 className="text-3xl lg:text-4xl text-blue-gray-700 font-bold">
        Build something great
      </h2>
      <p className="mt-2 max-w-lg text-gray-500 font-normal">
        We&apos;re constantly trying to express ourselves and actualize our
        dreams. If you have the opportunity to play this game of life, you need
        to appreciate every moment.
      </p>

      <div className="mt-10 grid grid-cols-1 gap-6 lg:grid-cols-3 lg:gap-10">
        {contents.map(({ img, title, desc }) => (
          <ContentCard key={title} img={img} title={title} desc={desc} />
        ))}
      </div>
    </section>
  );
}

export default BlogSection11;
