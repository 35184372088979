import React from "react";

const DynamicBanner = ({ text }) => {
  return (
    <div className="h-40 bg-blue-500 flex items-center justify-center text-white text-2xl font-bold">
      {text}
    </div>
  );
};

export default DynamicBanner;
