import React from "react";

const HeroSectionWithCards = () => {
  return (
    <section className="bg-[#F2F7FF] md:h-screen flex items-center mb-20">
      <div className="container mx-auto px-6 lg:px-16 flex flex-col lg:flex-row items-center justify-between ">
        {/* Left Text Section */}
        <div className="lg:w-1/2 text-center lg:text-left space-y-4 lg:space-y-6 p-6">
          <h1 className="text-3xl sm:text-5xl font-bold text-gray-800">
            What makes us excel in these four domains?
          </h1>
          <p className="text-gray-600 text-lg">
            We are global leaders in delivering transformative solutions across
            Consulting, Training, Recruitment, and Event Management.
          </p>
          <button className="bg-[#6293CC] text-white px-6 py-3 rounded-lg text-sm sm:text-lg font-medium hover:bg-blue-600">
            Know More
          </button>
        </div>

        {/* Right Cards Section */}
        <div className="lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 lg:mt-0">
          {/* Card 1 */}
          <div className="bg-white p-6 rounded-xl shadow-lg text-left">
            <div className="text-blue-500 text-3xl mb-4">
              <i className="fas fa-chalkboard-teacher"></i>
            </div>
            <h2 className="text-xl font-semibold text-gray-800">
              Training and Development
            </h2>
            <p className="text-gray-400 mt-2">
              Empowering growth through training, skill development, and
              specialized programs.
            </p>
          </div>

          {/* Card 2 */}
          <div className="bg-white p-6 rounded-xl shadow-lg text-letf">
            <div className="text-blue-500 text-3xl mb-4">
              <i className="fas fa-briefcase"></i>
            </div>
            <h2 className="text-xl font-semibold text-gray-800">
              Consulting Services
            </h2>
            <p className="text-gray-400 mt-2">
              Expert solutions in HR, business optimization, and educational
              enhancement.
            </p>
          </div>

          {/* Card 3 */}
          <div className="bg-white p-6 rounded-xl shadow-lg text-left">
            <div className="text-blue-500 text-3xl mb-4">
              <i className="fas fa-users"></i>
            </div>
            <h2 className="text-xl font-semibold text-gray-800">
              Recruitment and Staffing
            </h2>
            <p className="text-gray-400 mt-2">
              Tailored solutions for permanent, temporary, and specialized
              recruitment needs.
            </p>
          </div>

          {/* Card 4 */}
          <div className="bg-white p-6 rounded-xl shadow-lg text-left">
            <div className="text-blue-500 text-3xl mb-4">
              <i className="fas fa-calendar-alt"></i>
            </div>
            <h2 className="text-xl font-semibold text-gray-800">
              Event and Program Management
            </h2>
            <p className="text-gray-400 mt-2">
              Seamlessly organizing events, conferences, and talent programs
              with precision.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSectionWithCards;
