import React from "react";
import Fishing from "../Assets/Images/aboutpage.jpg"

const Aboutus = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-7xl mx-auto">
        {/* Heading Section */}
        <div className="text-center mb-16 px-4">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
            GRO SKILL WORLD
          </h2>
          <p className="text-lg md:text-xl text-gray-700 mb-8 leading-relaxed">
            The First-of-its-Kind All-in-One Finishing School in Kerala that
            Touches Everyone's Life.
          </p>
        </div>

        {/* What is Finishing School Section */}
        <section className="py-16 bg-gradient-to-r from-indigo-50 via-purple-50 to-indigo-100 mb-16 rounded-xl">
  <div className="max-w-7xl mx-auto px-6 flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0">
    {/* Text Section */}
    <div className="flex-1 text-center lg:text-left">
      <h3 className="text-4xl font-extrabold text-gray-900 mb-6 ">
        What is Finishing School?
      </h3>
      <p className="text-lg text-gray-800 leading-relaxed max-w-xl mx-auto lg:mx-0">
        Finishing School is the rise or blending of the term <strong>"skills"</strong> to the entireness of the personality. It's the necessity of the contemporary competitive world, as everything from raw elements rises to its sublime by getting a final finishing touch. GRO has been built to provide the learning community with modern industry requirements and future-oriented skills. The training programs identify inhibitions and convert them into strengths, helping individuals transform their current skill sets into manifest strengths.
      </p>
    </div>
    
    {/* Image Section */}
    <div className="flex-1 max-w-md mx-auto lg:mx-0">
      <img 
        src={Fishing} 
        alt="Finishing School" 
        className="rounded-lg shadow-lg w-full h-auto object-cover"
      />
    </div>
  </div>
</section>



        {/* Training Approach Section */}
        <section className="mb-16">
  <h3 className="text-4xl font-extrabold text-gray-900 text-center mb-12">
    Our Training Approach
  </h3>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 lg:px-0">
    {/* IT Skills & Sales & Marketing */}
    <div className="border border-black p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105">
      <h4 className="text-2xl font-semibold text-black mb-4">IT Skills & Sales & Marketing</h4>
      <p className="text-lg text-grey-300">
        The finishing school integrates academics and imparts practical
        knowledge of various IT skills and sales & marketing scenarios.
        Students get hands-on experience in customer dealing situations.
      </p>
    </div>
    
    {/* Media & Entertainment */}
    <div className="border border-black p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105">
      <h4 className="text-2xl font-semibold text-black mb-4">Media & Entertainment</h4>
      <p className="text-lg text-grey-300">
        This room lets students express their flair for various talents,
        helping them discover and hone their skills.
      </p>
    </div>
    
    {/* Communication Skills & Soft Skills */}
    <div className="border border-black p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105">
      <h4 className="text-2xl font-semibold text-black mb-4">Communication Skills & Soft Skills</h4>
      <p className="text-lg text-grey-300">
        Communication skills training through the LSRW technique
        (Listening, Speaking, Reading, Writing) and etiquette training,
        along with soft skills for confidence, decision-making, and time
        management.
      </p>
    </div>
  </div>
</section>


        {/* Vision and Mission Section */}
        <section className="mb-16">
  <h3 className="text-4xl font-extrabold text-gray-900 text-center mb-12">
    Vision & Mission
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 lg:px-0">
    {/* Vision Card */}
    <div className="bg-gray-50 shadow-md rounded-xl border border-black p-8 transition-transform transform hover:scale-105 hover:shadow-xl">
      <h4 className="text-2xl font-semibold text-gray-800 mb-4">Vision</h4>
      <p className="text-lg text-gray-600 leading-relaxed">
        To be a pioneer institution in the area of total skill development and
        consulting, helping all sectors of society reach global standards in
        terms of competitiveness, quality, and performance.
      </p>
    </div>

    {/* Mission Card */}
    <div className="bg-gray-50 shadow-md rounded-xl border border-black p-8 transition-transform transform hover:scale-105 hover:shadow-xl">
      <h4 className="text-2xl font-semibold text-gray-800 mb-4">Mission</h4>
      <p className="text-lg text-gray-600 leading-relaxed">
        To provide affordable training programs for people from all walks of
        life to help them reach their dream careers and positions. To mold the
        upcoming generations into real global Indian citizens with uncompromising values.
      </p>
    </div>
  </div>
</section>


        {/* Our Values Section */}
        <section>
          <h3 className="text-3xl font-semibold text-gray-800 mb-6">Our Values</h3>
          <div className="space-y-4 max-w-4xl mx-auto">
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Steadfast:</strong> Committed to creating successful
                outcomes and offering quality services.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Passion:</strong> Passionate about achieving what we aim
                for.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Excellence:</strong> Striving for excellence in services
                and products.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Cooperation:</strong> Building strong bonds and
                teamwork.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Innovation:</strong> Creating innovative solutions for
                outstanding products.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Accountable:</strong> Ensuring real value for money is
                our prime responsibility.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="h-2 w-2 bg-indigo-600 rounded-full mt-2"></div>
              <p className="text-lg text-gray-700">
                <strong>Legitimate:</strong> Upholding honesty, integrity, and
                quality in all our work.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Aboutus;
