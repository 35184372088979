import React from "react";
import Gro_logo from"../Assets/Images/Gro_logo.png"
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";

const Footer = () => {
  return (
    <footer className="bg-[#0F123D] text-white py-10 px-2">
      <div className="container mx-auto">
        {/* First Row: Logo */}
        <div className="flex justify-center  mb-10">
          <img
            src={Gro_logo}
            alt="Company Logo"
            className="w-auto h-20"
          />
        </div>

        {/* Second Row: Footer Links */}
        <div className="flex flex-col lg:flex-row lg:justify-between">
          {/* Reach Us Section */}
          <div className="lg:w-1/4 mb-8 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Reach Us</h3>
            <ul className="space-y-3">
              <li className="flex items-center">
                <span className="material-icons mr-4"><FaPhoneAlt /></span>
                9447370787
              </li>
              <li className="flex items-center">
                <span className="material-icons mr-2"><FaLocationDot /></span>
                INDIA | UAE | UK | USA | MALAYSIA
              </li>
              <li className="flex items-center">
                <span className="material-icons mr-2"><IoIosMail /></span>
                Info@gogrow.world
              </li>
            </ul>
          </div>

          {/* Company Section */}
          <div className="lg:w-1/8 mb-8 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>About</li>
              <li>Contact</li>
              <li>Blog</li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="lg:w-1/8 mb-8 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>Privacy</li>
              <li>Terms</li>
              <li>Terms of Use</li>
              <li>Refund Policy</li>
            </ul>
          </div>

          {/* Quick Links Section */}
          <div className="lg:w-1/8 mb-8 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>Form</li>
              <li>Download</li>
              <li>Keyword</li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="lg:w-1/4">
            <h3 className="text-lg font-semibold mb-4">Join the Newsletter</h3>
            <form className="flex flex-col space-y-3">
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded bg-gray-800 text-gray-300 border border-gray-700"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              >
                Send
              </button>
            </form>
            <p className="text-sm text-gray-400 mt-4">
              We will send the newsletter to your inbox.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
