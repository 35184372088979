import React from "react";
import callImage from '../Assets/Images/callimage.png'

const CTARequestCall = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        {/* Left Side - Text Content */}
        <div className="lg:w-3/4 w-full px-6 md:px-16 lg:pr-12">
          <h2 className="text-3xl sm:text-5xl font-bold text-gray-800">
            Complex Questions?
          </h2>
          <p className="text-gray-400 mt-4 text-lg">
            Request a personalized plan for your business needs. Receive options
            within 24 hours. Free consultation for first-time customers.
          </p>
          <button className="mt-6 px-6 py-3 bg-blue-500 text-white font-medium rounded-lg hover:bg-blue-600 transition">
            Call Now
          </button>
        </div>

        {/* Right Side - Image */}
        <div className="lg:w-1/4 w-full mt-8 lg:mt-0 flex justify-center">
          <img
            src={callImage}
            alt="CTA Illustration"
            className="max-w-full rounded-lg shadow-md"
          />
        </div>
      </div>
    </section>
  );
};

export default CTARequestCall;
