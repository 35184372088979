import React from "react";

const CTASection = () => {
  return (
    <section className="bg-[#0F123D] py-16">
      <div className="container mx-auto text-center">
        {/* Heading */}
        <h2 className="text-3xl sm:text-4xl font-bold text-[#FFFFFF]">
          Some Count That Matters
        </h2>

        {/* Subheading */}
        <p className="text-gray-300 text-lg mt-4">
          Our achievement in the journey depicted in numbers
        </p>

        {/* Stats Section */}
        <div className="flex flex-col sm:flex-row justify-center items-center mt-10 space-y-6 sm:space-y-0 sm:space-x-12">
          {/* Column 1 */}
          <div className="text-center">
            <h3 className="text-4xl font-bold text-blue-500">30+</h3>
            <p className="text-gray-300">Clients</p>
          </div>

          {/* Vertical Bar */}
          <div className="hidden sm:block h-16 border-l border-gray-400"></div>

          {/* Column 2 */}
          <div className="text-center">
            <h3 className="text-4xl font-bold text-blue-500">300+</h3>
            <p className="text-gray-300">Takes business growth further</p>
          </div>

          {/* Vertical Bar */}
          <div className="hidden sm:block h-16 border-l border-gray-400"></div>

          {/* Column 3 */}
          <div className="text-center">
            <h3 className="text-4xl font-bold text-blue-500">8</h3>
            <p className="text-gray-300">Years of Journey</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
