import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import DynamicBanner from '../Components/DynamicBanner'
import Aboutus from '../Components/Aboutus'

const AboutUS = () => {
  return (
    <div>
        <Navbar />
       <Aboutus />
        <Footer />
    </div>
  )
}

export default AboutUS