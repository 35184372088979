import React from 'react'
import Navbar from '../Components/Navbar'
import HeroSection from '../Components/HeroSection'
import HeroSectionWithCards from '../Components/HeroSectionwithCard'
import ImageTextSection from '../Components/ImageTextSection'
import CTASection from '../Components/CTASection'
import ClientTestimonial from '../Components/ClientTestimonial'
import CTARequestCall from '../Components/CTARequestCall'
import Footer from '../Components/Footer'

const Home = () => {
  return (
   <div>
    <Navbar />
    <HeroSection />
    <HeroSectionWithCards />
    <ImageTextSection />
    <CTASection />
    <ClientTestimonial />
    <CTARequestCall />
<Footer />
   </div>
  )
}

export default Home