import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import BlogSection11 from '../Components/BlogPost'

const Blog = () => {
  return (
    <div>
        <Navbar />
        <BlogSection11 />
        <Footer />
    </div>
  )
}

export default Blog