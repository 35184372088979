import React from "react";

const locations = [
  {
    name: "United Kingdom",
    image: "https://pagedone.io/asset/uploads/1696246502.png",
  },
  {
    name: "UAE",
    image: "https://pagedone.io/asset/uploads/1696246522.png",
  },
  {
    name: "USA",
    image: "https://pagedone.io/asset/uploads/1696246551.png",
  },
  {
    name: "India",
    image: "https://pagedone.io/asset/uploads/1696246565.png",
  },
];

export default function ContactSection() {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top Section */}
        <div className="flex flex-col md:flex-row items-center md:items-start gap-y-10 gap-x-24 mb-16">
          <div className="text-center md:text-left md:flex-1">
            <h2 className="text-black text-4xl font-semibold leading-10 mb-5">
              Get In Touch
            </h2>
            <p className="text-gray-600 text-lg font-normal leading-7 mb-7">
              Whether you have a concern or simply want to say hello, we are
              here to facilitate communication with you.
            </p>
            <button className="w-36 h-12 rounded-full bg-indigo-600 transition-all duration-300 hover:bg-indigo-800 shadow text-white text-base font-semibold">
              Contact Us
            </button>
          </div>
          <div className="border-l-2 border-indigo-600 px-10 py-6 flex-1">
            <div className="mb-8">
              <h6 className="text-gray-500 text-sm font-medium leading-5 mb-2">
                Email Address
              </h6>
              <p className="text-black text-xl font-semibold">
              Info@gogrow.world
              </p>
            </div>
            <div>
              <h6 className="text-gray-500 text-sm font-medium leading-5 mb-2">
                Phone Number
              </h6>
              <p className="text-black text-xl font-semibold">9447370787</p>
            </div>
          </div>
        </div>

        {/* Location Cards */}
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {locations.map(({ name, address, image }, index) => (
            <div
              key={index}
              className="relative h-80 flex justify-center overflow-hidden rounded-lg shadow-lg group"
            >
              {/* Background Image */}
              <img
                src={image}
                alt={`${name} location`}
                className="absolute inset-0 h-full w-full object-cover object-center"
              />
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent group-hover:from-black/80 group-hover:via-black/40 transition-all"></div>
              {/* Content */}
              <div className="absolute bottom-4 text-center px-4">
                <h5 className="text-white text-lg font-semibold mb-2">
                  {name}
                </h5>
                <p className="text-white text-sm">{address}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
