import React from 'react'

function ContentCard({ img, title, desc }) {
    return (
      <div className="relative grid min-h-[15rem] items-end overflow-hidden rounded-xl shadow-lg hover:scale-105 transition-transform duration-300">
        {/* <img
          src={img}
          alt={`${title} background image`}
          className="absolute inset-0 h-full w-full object-cover object-center"
        /> */}
        <div className="absolute inset-0 bg-[#0FBABC]" />
        <div className="relative p-6 text-white">
          <h4 className="text-2xl mb:text-4xl font-bold">{title}</h4>
          {/* <p className="mt-2 text-lg">{desc}</p> */}
        </div>
      </div>
    );
  }

const Services3 = () => {
    const contents = [
        {
          img: "https://www.material-tailwind.com/image/blog-11.jpeg",
          title: "Permanent Staffing",
          desc: "",
        },
        {
          img: "https://www.material-tailwind.com/image/blog-10.jpeg",
          title: "Temporary and Contract Staffing",
          desc: "Wealth creation is an evolutionarily recent positive-sum game. Status is an old zero-sum game. Those attacking wealth creation are often just seeking status.",
        },
        {
          img: "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/card-blog2.jpg",
          title: "Bulk Hiring and Campus Recruitment",
          desc: "Free people make free choices. Free choices mean you get unequal outcomes. You can have freedom, or you can have equal outcomes. You can't have both.",
        },
        {
            img: "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/card-blog2.jpg",
            title: "Specialized Recruitment",
            desc: "Free people make free choices. Free choices mean you get unequal outcomes. You can have freedom, or you can have equal outcomes. You can't have both.",
          },
      ];
  return (
    <div>
        <section className="bg-[#F2F7FF] p-2 mb:p-20  flex items-center mb-20">
      <div className="container mx-auto px-6 lg:px-16 flex flex-col lg:flex-row items-center justify-between ">
        {/* Left Text Section */}
        <div className="lg:w-1/2 text-center lg:text-left space-y-4 lg:space-y-6 p-6 ">
          <h1 className="text-3xl sm:text-5xl font-bold text-gray-800">
          Recruitment and Staffing
          </h1>
          <p className="text-gray-600 text-lg">
          Delivering efficient and customized hiring solutions, including permanent, temporary, bulk, and industry-specific recruitment. We connect talent with opportunity for sustainable growth.
         </p>
          <button className="bg-[#6293CC] text-white px-6 py-3 rounded-lg text-sm sm:text-lg font-medium hover:bg-blue-600">
            Know More
          </button>
        </div>

        {/* Right Cards Section */}
        <div className="lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8 lg:mt-0 h-1/2">
          {/* Card 1 */}
          {contents.map(({ img, title, desc }) => (
          <ContentCard key={title} img={img} title={title} desc={desc} />
        ))}
        </div>
      </div>
    </section>
    </div>
  )
}

export default Services3