import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ContactCard from '../Components/ContactCard'
import ContactSection from '../Components/ContactCard'

const Contact = () => {
  return (
    <div>
        <Navbar />
     <ContactSection />
        <Footer />
        </div>
  )
}

export default Contact