import React from 'react'
import DynamicBanner from '../Components/DynamicBanner'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Services1 from '../Components/Services1'
import Services2 from '../Components/Service2'
import Services3 from '../Components/Service3'
import Services4 from '../Components/Service4'



const Services = () => {
  return (
    <div>
        <Navbar />
<Services1 />
<Services2 />
<Services3 />
<Services4 />
  <Footer/>
    </div>
  )
}

export default Services